
























import { Vue, Component, Prop } from 'vue-property-decorator';
import SectionContainer from "@/components/section/SectionContainer.vue";
import OrgCard from './components/OrgCard.vue';
import DeleteOrgCard from './components/DeleteOrgCard.vue';
import SystemCard from './components/SystemCard.vue';
import Sources from './sources/index.vue';
import { queries, schema } from '@/gql';
import { mdiInformation } from '@mdi/js'

@Component({
  components: {
    SectionContainer,
    OrgCard,
    DeleteOrgCard,
    SystemCard,
    Sources,
  },
  apollo: {
    org: {
      fetchPolicy: 'cache-first',
      query: queries.org,
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {
          uid: this.orgUid,
        };
      },
      update(query: schema.Query) {
        this.name = query.org.displayName;
        return query.org;
      },
    },
  },
})
export default class Settings extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private org!: schema.Org;
  private mdiInformation = mdiInformation;

  private get writable(): boolean {
    return this.org?.authorizeWrite || false;
  }
}
