














import { Vue, Component, Prop } from 'vue-property-decorator';
import { SourceType } from '@arkive-ai/client-gateway-gql/dist/schema';
import sourceTypeMdi from '@/common/sourceTypeMdi';

@Component
export default class OAuthButton extends Vue {
  @Prop({ default: '' }) readonly type!: string;
  @Prop(String) readonly sourceType!: SourceType;
  @Prop({ default: '', type: String }) readonly color!: string;
  @Prop(String) readonly textClass!: string;
  @Prop(String) readonly text!: string;
  @Prop({ default: false, type: Boolean }) readonly loading!: boolean;

  private get icon() {
    return sourceTypeMdi(this.sourceType);
  }

  private click() {
    this.$emit('clicked', this.sourceType);
  }
}
