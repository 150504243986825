













import { Vue, Component, Prop } from 'vue-property-decorator';
import { queries, schema } from '@/gql';

@Component({
  apollo: {
    channels: {
      fetchPolicy: 'cache-and-network',
      query: queries.sourceChannels,
      skip() {
        return !this.sourceUid;
      },
      variables(): schema.QuerySourceArgs {
        return {
          uid: this.sourceUid,
        };
      },
      update(query: schema.Query) {
        return query.source?.channels || [];
      },
    },
  },
})
export default class ChannelSelect extends Vue {
  @Prop(String) private sourceUid!: string;
  @Prop(String) private ariaLabelledby!: string;
  @Prop({ default: null, type: String}) private selection!: string | null;
  private channels: schema.Channel[] = [];
  
  private get loading() {
    return this.$apollo.queries.channels.loading;
  }

  private get channelItems() {
    const channels = this.channels || [];

    return channels.map(channel => {
      return {
        text: `${channel.displayName} (${channel.categoryName})`,
        value: channel.eid,
      };
    });
  }

  private get channelSelected() {
    const selectedChannel = this.channelItems.find((i) => i.value == this.selection);
    if (!selectedChannel) return null;
    return selectedChannel;
  }

  private set channelSelected(item) {
    if (item) {
      this.$emit("update:selection", item.value);
    }
  }
}
