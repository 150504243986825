
















import { Vue, Component, Prop } from 'vue-property-decorator';
import SectionContainer from "@/components/section/SectionContainer.vue";
import KnowledgeBaseRecent from "./components/KnowledgeBaseRecent.vue";

@Component({
  components: {
    SectionContainer,
    KnowledgeBaseRecent,
  }
})
export default class Knowledgebase extends Vue {
  @Prop(String) private readonly orgUid!: string;
}
