






import { Vue, Component, Prop } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
//import { queries, schema } from '@/gql';


@Component({
  components: {
    HeadingCard,
  },
  // apollo: {
  //   org: {
  //     query: queries.org,
  //     fetchPolicy: 'cache-first',
  //     skip() {
  //       return !this.isValid;
  //     },
  //     variables(): schema.QueryOrgArgs {
  //       return {
  //         uid: this.orgUidOrDomain,
  //       };
  //     },
  //   },
  // },
})
export default class WebCard extends Vue {
  @Prop(String) private readonly sourceUid!: string
}
