










import { Vue, Component, Prop } from 'vue-property-decorator';
import PlaceholderCard from '@/components/section/PlaceholderCard.vue';
import HeadingCard from '@/components/section/HeadingCard.vue';
import DiscordCard from './components/DiscordCard.vue';
import WebCard from './components/WebCard.vue';
import { queries, schema } from '@/gql';

@Component({
  components: {
    PlaceholderCard,
    HeadingCard,
    DiscordCard,
    WebCard,
  },
  apollo: {
    sources: {
      fetchPolicy: 'cache-and-network',
      query: queries.org,
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {
          uid: this.orgUid,
        };
      },
      update(query: schema.Query) {
        return query.org?.sources.edges.map(item => item.node) || [];
      },
    },
  },
})
export default class Sources extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private sources: schema.Source[] = [];

  private get loading() {
    return (this.sources.length == 0) && this.$apollo.queries.sources.loading;
  }
  private get webSources() {
    return this.sources.filter(source => source.sourceType == schema.SourceType.Web);
  }
  private get discordSources() {
    return this.sources.filter(source => source.sourceType == schema.SourceType.Discord);
  }
}
