


























import { Vue, Component, Prop } from 'vue-property-decorator';
import PlaceholderCard from '@/components/section/PlaceholderCard.vue';
import ArkMessage from '@/components/output/ArkMessage.vue';
import { queries, schema } from '@/gql';
import { buildChannelTreeFromQuery, flattenChannels } from '@/common/channelTree';
import { mdiInformation } from '@mdi/js';

@Component({
  components: {
    PlaceholderCard,
    ArkMessage,
  },
  apollo: {
    channelUids: {
      fetchPolicy: 'cache-and-network',
      query: queries.channelTree,
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return { uid: this.orgUid };
      },
      update(query: schema.Query): string[] {
        const root = buildChannelTreeFromQuery(query.org);
        const channels = flattenChannels(root);
        const uids = channels.filter((channel) => channel.type === 'channel').map((channel) => channel.id);

        return uids;
      },
    },
    unansweredQuestions: {
      query: queries.recent,
      fetchPolicy: 'network-only',
      variables(): schema.QueryRecentQuestionsListArgs {
        return {
          channelUids: this.channelUids,
          questionsOnly: true,
          answered: false,
          limit: 2,
        };
      },
      error(err) {
        console.error(err);
      },
      skip() {
        return !this.channelUids || this.channelUids.length === 0;
      },
      update(query: schema.Query): schema.ArkMessage[] {
        return query.recentQuestionsList?.edges.map(edge => edge.node) || []
      },
    },
  }
})
export default class RecentMini extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private mdiInformation = mdiInformation;
  private channelUids: string[] = [];

  private unansweredQuestions: schema.ArkMessage[] = [];

  private get limitedUnansweredQuestions() {
    return this.unansweredQuestions.slice(0, 4);
  }
  
  private get loading() {
    return this.unansweredQuestions.length == 0 && (this.$apollo.queries.unansweredQuestions.loading || this.$apollo.queries.channelUids.loading);
  }
}
