




import { Vue, Component, Prop } from 'vue-property-decorator';
import ChipTooltip from './ChipTooltip.vue';

@Component({
  components: {
    ChipTooltip,
  }
})
export default class RoleChipTooltip extends Vue {
  @Prop(String) private readonly role!: string;

  private get aRole() {
    return `${"aeiouAEIOU".indexOf(this.role[0]) < 0 ? 'a' : 'an'} ${this.role}`;
  }
  private get tooltip() {
    return `You are ${this.aRole} of this community`;
  }
}
