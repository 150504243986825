










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CardSheet extends Vue {
  @Prop(String) private readonly title!: string;
  @Prop([String, Number]) private readonly height!: string | number;
  @Prop({ default: 'grey' }) readonly barColor!: string;
};
