



























import { Vue, Component, Prop } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
import { proxies } from '@/store';
import { schema } from '@/gql';
import { gql } from 'apollo-boost';

@Component({
  components: {
    HeadingCard,
  },
})
export default class DeleteOrgCard extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private snackbar = proxies.Snackbar;
  private dialog = false;
  private loading = false;

  private async submit() {
    if (this.loading) return;
    this.loading = true;

    try {
      await this.$apollo.mutate<schema.Mutation>({
        mutation: gql`
          mutation deleteOrg($input: OrgDeleteInput!) {
            orgDelete(input: $input)
          }
        `,
        variables: {
          input: {
            uid: this.orgUid,
          }
        },
      });
      await this.$router.push({ name: 'communities' });
      this.dialog = false;
    } catch (err) {
      console.error(err);
      this.snackbar.showError('There was a problem when deleting this community');
    } finally {
      this.loading = false;
    }
  }
}
