



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { mdiInformation } from '@mdi/js';

@Component
export default class IconTooltip extends Vue {
  @Prop(String) private readonly tooltip!: string;
  @Prop(Boolean) private readonly left!: boolean;
  @Prop(Boolean) private readonly right!: boolean;
  @Prop({ default: "grey" }) private readonly color!: string;
  private mdiInformation = mdiInformation;
}
