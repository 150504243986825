












import { Vue, Component, Prop } from 'vue-property-decorator';
import ChipTooltip from '@/components/tooltip/ChipTooltip.vue';
import { mdiCheckCircle, mdiAlertCircle } from '@mdi/js'
import { gql } from 'apollo-boost';
import { queries, schema } from '@/gql';

@Component({
  components: {
    ChipTooltip,
  },
  apollo: {
    source: {
      fetchPolicy: 'network-only',
      query: gql`query sourceAspectStatus($uid: String!) {
        source(uid: $uid) {
          id
          aspect {
            id
            status
          }
        }
      }`,
      skip() {
        return !this.sourceUid;
      },
      variables(): schema.QuerySourceArgs {
        return {
          uid: this.sourceUid,
        };
      },
    },
  },
})
export default class Status extends Vue {
  @Prop(String) private readonly sourceUid!: string
  private source!: schema.Source;

  private mdiCheckCircle = mdiCheckCircle;
  private mdiAlertCircle = mdiAlertCircle;

  private get loading() {
    return this.$apollo.queries.source.loading;
  }

  private get statusIsOnline() {
    return this.source?.aspect.status === schema.AspectStatus.Online;
  }
}
