




import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class OrgWrapper extends Vue {
  @Prop(String) private readonly orgUid!: string
}
