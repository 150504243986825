




















import { Vue, Component, Prop } from 'vue-property-decorator';
import SectionContainer from "@/components/section/SectionContainer.vue";
import SearchInput from '@/components/input/SearchInput.vue';
import RecentMini from './components/RecentMini.vue';


@Component({
  components: {
    SectionContainer,
    SearchInput,
    RecentMini,
  }
})
export default class Dashboard extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private tab: number | null = null;

}
