













































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CardSheet from '@/components/section/CardSheet.vue';
import AutoresponseChannels from './AutoresponseChannels.vue';
import IconTooltip from '@/components/tooltip/IconTooltip.vue';
import ChannelSelect from '@/components/input/ChannelSelect.vue';
import { proxies } from '@/store';

import { SourceConfigModel } from '@arkive-ai/client-papi-rest';
import { queries, schema, mutations } from '@/gql';
import { mdiChevronDown, mdiInformation } from '@mdi/js';

@Component({
  components: {
    CardSheet,
    ChannelSelect,
    AutoresponseChannels,
    IconTooltip,
  },
  apollo: {
    source: {
      fetchPolicy: 'cache-and-network',
      query: queries.sourceConfig,
      skip() {
        return !this.sourceUid;
      },
      variables(): schema.QuerySourceArgs {
        return {uid: this.sourceUid};
      }
    },
    channels: {
      fetchPolicy: 'cache-and-network',
      query: queries.sourceChannels,
      skip() {
        return !this.autoresponseTime.value;
      },
      variables(): schema.QuerySourceArgs {
        return {uid: this.sourceUid};
      },
      update(query: schema.Query) {
        return query.source?.channels || [];
      },
    },
  },
})
export default class Autoresponse extends Vue {
  @Prop(String) private readonly sourceUid!: string
  private source!: schema.Source;
  private snackbar = proxies.Snackbar;
  private channels: schema.Channel[] = [];
  private mdiChevronDown = mdiChevronDown;

  private expandSettings: boolean = false;
  private updating: boolean = false;

  private get loading() {
    return this.$apollo.queries.source.loading || this.updating;
  }

  private get config(): SourceConfigModel | null {
    return this.source && JSON.parse(this.source.configJson);
  }

  private get channelsEnabled() {
    return this.channels.reduce((count, channel) => {
      if (channel.visible) {          
        const config = JSON.parse(channel.configJson);
        if (config.autoresponse) {
          return count + 1;
        }
      }
      return count;
    }, 0)
  }

  private timeItems = [
    { text: "No Autoresponse", value: 0 },
    { text: "5 minutes", value: 300 },
    { text: "10 minutes", value: 600 },
    { text: "15 minutes", value: 900 },
    { text: "30 minutes", value: 1500 },
    { text: "1 hour", value: 3600 },
    { text: "4 hour", value: 14400 },
  ]

  private get autoresponseTime() {
    // Select closest item to value
    if (!this.config || !this.config.autoresponseTime) {
      return this.timeItems[0]
    }

    for (let item of this.timeItems) {
      if (item.value && this.config.autoresponseTime <= item.value) {
        return item
      }
    }

    return this.timeItems[0];
  }
  
  private set autoresponseTime(item) {
    if (item.value > 0) {
      this.expandSettings = true;
    }

    (async () => this.updateConfig({
      ...this.config,
      autoresponseTime: item.value,
    }))();
  }

  private get whereItems() {
    const items = [
      { text: "Reply to asker in same channel as question", eid: '', dm: false },
      { text: "Send to asker in DM, fallback to reply in same channel", eid: '', dm: true },
    ]

    return items.concat(this.channels.map(channel => {
      return {
        text: `${channel.displayName} (${channel.categoryName})`,
        eid: channel.eid,
        dm: false,
      };
    }));
  }

  private get autoresponseWhere() {
    if (this.config?.autoresponsePreviewChannelEid) {
      const selectedChannel = this.whereItems.find((i) => i.eid == this.config?.autoresponsePreviewChannelEid);
      if (!selectedChannel) return this.whereItems[0]
      return selectedChannel
    }
    if (this.config?.autoresponseDm) {
      return this.whereItems[1]
    }
    return this.whereItems[0];
  }

  private set autoresponseWhere(item) {
    (async () => this.updateConfig({
      ...this.config,
      autoresponseDm: item.dm,
      autoresponsePreviewChannelEid: item.eid,
    }))();
  }

  private async updateConfig(config: SourceConfigModel) {
    this.updating = true;
    try {
      await this.$apollo.mutate<schema.SourceUpdateInput>({
        mutation: mutations.sourceUpdate,
        variables: {
          input: {
            uid: this.sourceUid,
            sourceType: schema.SourceType.Discord,
            configJson: JSON.stringify(config)
          },
        },
      });
      this.snackbar.showSuccess('Source updated!');
    } catch (err) {
      this.snackbar.showError('There was an error updating settings');
      console.error(err);
    } finally {
      this.updating = false;
    }
  }
}
