












































import { Vue, Component, Prop } from 'vue-property-decorator';
import { proxies } from '@/store';
import { queries, schema, mutations } from '@/gql';
import CardSheet from '@/components/section/CardSheet.vue';
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight } from '@mdi/js';

@Component({
  components: {
    CardSheet,
  },
  apollo: {
    channels: {
      fetchPolicy: 'cache-and-network',
      query: queries.sourceChannels,
      skip() {
        return !this.sourceUid;
      },
      variables(): schema.QuerySourceArgs {
        return {
          uid: this.sourceUid,
        };
      },
      update(query: schema.Query) {
        return query.source?.channels || [];
      },
    },
  },
})
export default class Channels extends Vue {
  @Prop(String) private readonly sourceUid!: string
  private snackbar = proxies.Snackbar;
  private mdiChevronDown = mdiChevronDown;
  private footerProps = {
    'next-icon':mdiChevronRight,
    'prev-icon':mdiChevronLeft,
  }
  private expandSettings: boolean = false;

  private channels: schema.Channel[] = [];
  private updating = false;
  
  private get loading() {
    return this.$apollo.queries.channels.loading || this.updating;
  }

  private get channelTableHeaders() {
    return [
      {
        text: 'Channel Name',
        value: 'channelName',
      },
      {
        text: 'Category',
        value: 'categoryName',
      },
      {
        text: 'Visible',
        value: 'visible',
      },
    ];
  }

  private get channelTableItems() {
    const channels = this.channels || []

    return channels.map(channel => {
      return {
        uid: channel.uid,
        channelName: channel.displayName,
        categoryName: channel.categoryName,
        visible: channel.visible,
      };
    });
  }

  private get channelsEnabled() {
    return this.channels.reduce((count, channel) => {
      if (channel.visible) {          
        return count + 1;
      }
      return count;
    }, 0)
  }

  private async onVisibleChanged(uid: string, visible: boolean) {
    if (this.updating) return;
    this.updating = true;

    const input: schema.ChannelUpdateInput = {
      uid,
      visible,
    }

    try {
      await this.$apollo.mutate({
        mutation: mutations.channelUpdate,
        variables: { input },
      });
      this.snackbar.showSuccess('Channel Updated!');
      this.$apollo.queries.channels.refresh()
    } catch (err) {
      console.error(err);
      this.snackbar.showError('There was an error changing settings!')
    } finally {
      this.updating = false;
    }
  }
}
