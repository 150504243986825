

























import { gql } from 'apollo-boost';
import { queries, schema } from '@/gql';
import { Vue, Component, Prop } from 'vue-property-decorator';

import PlaceholderCard from '@/components/section/PlaceholderCard.vue';
import KnowledgeArticleMini from '@/components/output/KnowledgeArticleMini.vue';
import { mdiInformation } from '@mdi/js'

@Component({
  components: {
    PlaceholderCard,
    KnowledgeArticleMini,
  },
  apollo: {
    knowledgebaseChannelUid: {
      fetchPolicy: 'cache-and-network',
      query: queries.org,
      variables(): schema.QueryOrgArgs {
        return {uid: this.orgUid,};
      },
      skip() {
        return !this.orgUid;
      },
      update(query: schema.Query) {
        return query.org?.knowledgebase.uid;
      },
    },
    results: {
      fetchPolicy: 'network-only', // see: https://github.com/vuejs/vue-apollo/issues/501
      loadingKey: 'loadingCount',
      query: gql`
        query knowledgebaseRecent($channelUids: [String!]!, $questionsOnly: Boolean!, $answered: Boolean, $limit: Float!) {
          recentQuestionsList(channelUids: $channelUids, questionsOnly: $questionsOnly, answered: $answered, limit: $limit) {
            edges {
              node {
                id
                eid
                authorUid
                timestamp
                joinedMessage {
                  text
                }
                answers {
                  id
                  eid
                  contributors {
                    id
                    uid
                  }
                  joinedMessage {
                    text
                  }
                }
              }
            }
          }
        }
      `,
      variables(): schema.QueryRecentQuestionsListArgs {
        return {
          channelUids: [this.knowledgebaseChannelUid],
          questionsOnly: true,
          answered: true,
          limit: this.maxResults,
        };
      },
      skip() {
        return !this.knowledgebaseChannelUid;
      },
      update(query: schema.Query): schema.ArkMessage[] {
        const results = query.recentQuestionsList.edges || [];
        return results.map((item) => item.node)
      },
    },
  },
})
export default class KnowledgeBaseRecent extends Vue {
  @Prop(String) private readonly orgUid!: string;
  @Prop({ default: 10 }) private maxResults!: number;
  private knowledgebaseChannelUid: string | null = null;
  private results: schema.ArkMessage[] = [];
  private mdiInformation = mdiInformation;

  private get loading(): boolean {
    return (this.results.length == 0) && this.$apollo.queries.results.loading;
  }
}
