












































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PlaceholderCard from '@/components/section/PlaceholderCard.vue';
import HeadingCard from '@/components/section/HeadingCard.vue';
import RoleChipTooltip from '@/components/tooltip/RoleChipTooltip.vue';
import { queries, schema } from '@/gql';
import { gql } from 'apollo-boost';
import { Validate } from 'vuelidate-property-decorators';
import { required } from "vuelidate/lib/validators";
import { proxies } from '@/store';
import { mdiAccountMultiple, mdiRobot, mdiWeb } from '@mdi/js';

@Component({
  components: {
    PlaceholderCard,
    HeadingCard,
    RoleChipTooltip,
  },
  apollo: {
    org: {
      fetchPolicy: 'cache-first',
      query: queries.org,
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {
          uid: this.orgUid,
        };
      },
      update(query: schema.Query) {
        this.name = query.org.displayName;
        return query.org;
      },
    },
  },
})
export default class OrgCard extends Vue {
  private snackbar = proxies.Snackbar;
  @Prop(String) private readonly orgUid!: string;

  private org!: schema.Org;
  private mdiAccountMultiple = mdiAccountMultiple;
  private mdiRobot = mdiRobot;
  private mdiWeb = mdiWeb;

  private get loading() {
    return (!this.org && this.$apollo.queries.org.loading) || this.submitLoading;
  }

  private get userSourceCount() {
    return this.org?.userSourceCount < 0 ? '?' : this.org.userSourceCount;
  }
  private get sourceCount() {
    return this.org?.sources?.edges.filter((i) => i.node.sourceType !== schema.SourceType.Web).length;
  }
  private get platformUrl(): string {
    return `https://${location.host}/org/${this.orgUid}`;
  }
  private get domainUrl(): string {
    if (this.org?.domain) {
      return `https://${this.org?.domain}.arkive.wiki`;
    }
    return '';
  }
  private get writable(): boolean {
    return this.org?.authorizeWrite || false;
  }

  private dialog = false;
  private submitLoading = false;

  @Validate({ required })
  private name = "";
  
  private get nameErrors() {
    const errors: string[] = [];
    if (!this.$v.name.$dirty) return errors;
    if (!this.$v.name.required) errors.push("Name is required.");
    return errors;
  }

  private async submit() {
    this.$v.$touch();
    if (this.loading || this.$v.$invalid) return;
    this.submitLoading = true;

    try {
      await this.$apollo.mutate<schema.Mutation>({
        mutation: gql`
          mutation settingsOrgCardUpdateOrgDisplayName($orgUid: String!, $displayName: String!) {
            orgUpdate(input: { uid: $orgUid, displayName: $displayName }) {
              id
              uid
              displayName
            }
          }
        `,
        variables: {
          orgUid: this.orgUid,
          displayName: this.name,
        },
      });
      this.snackbar.showSuccess('Name successfully changed!');
      this.dialog = false;
      this.$apollo.queries.org.refresh();
    } catch (err) {
      console.error(err)
      this.snackbar.showWarning('There was an error editing community name');
    } finally {
      this.submitLoading = false;
    }
  }
}
