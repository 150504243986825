






































































import { Vue, Component, Prop } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
import UserAvatarTooltip from '@/components/output/UserAvatarTooltip.vue';
import RelativeTimeTooltip from '@/components/tooltip/RelativeTimeTooltip.vue';
import ChannelBreadcrumbs from '@/components/output/ChannelBreadcrumbs.vue';
import { queries, schema } from '@/gql';
import { gql } from 'apollo-boost';
import { proxies } from '@/store';
import { mdiDotsHorizontal } from '@mdi/js';

@Component({
  components: {
    HeadingCard,
    UserAvatarTooltip,
    RelativeTimeTooltip,
    ChannelBreadcrumbs,
  },
  apollo: {
    answers: {
      fetchPolicy: 'cache-and-network',
      query: gql`
        query ArkMessageAnswers($id: String!) {
          arkMessage(id: $id) {
            id
            answers {
              id
              timestamp
              aspectMessage {
               id
               eid
               text
              }
              contributors {
                id
                uid
              }
            }
          }
        }
      `,
      variables(): schema.QueryArkMessageArgs {
        return {
          id: this.messageId,
        };
      },
      skip() {
        return !this.messageId && this.loadAnswers;
      },
      update(query: schema.Query) {
        if (this.isOpenInDrawer) {
          // update drawer payload if so
          this.drawer.setPayload({
            channelUid: this.channelUid,
            questionEid: this.eid,
            questionTimestamp: this.timestamp,
            answerEids: query.arkMessage?.answers.map(answer => answer.aspectMessage?.eid || '') || [],
            correlationId: this.messageId,
          });
        }
        return query.arkMessage?.answers;
      },
    },
    aspectMessage: {
      fetchPolicy: 'cache-and-network',
      query: gql`
        query ArkMessageAspectMessage($id: String!) {
          arkMessage(id: $id) {
            id
            aspectMessage {
              id
              text
              externalUrl
            }
          }
        }
      `,
      variables(): schema.QueryArkMessageArgs {
        return {
          id: this.messageId,
        };
      },
      skip() {
        return !this.messageId;
      },
      update(query: schema.Query) {
        if (!query.arkMessage) {
          this.deleted = true;
        }
        const text = query.arkMessage?.aspectMessage?.text || []
        return text.join('') || '';
      },
    },
    sourceType: {
      fetchPolicy: 'cache-first',
      query: queries.channelBreadcrumbs,
      variables(): schema.QueryChannelArgs {
        return {
          uid: this.channelUid,
        };
      },
      skip() {
        return !this.channelUid;
      },
      update(query: schema.Query) {
        return query.channel?.source?.sourceType;
      },
    },
  },
})
export default class ArkMessage extends Vue {
  private theme = proxies.Theme;
  private snackbar = proxies.Snackbar;
  private history = proxies.History;

  @Prop(String) private readonly channelUid!: string;
  @Prop(String) private readonly authorUid!: string;
  @Prop(String) private readonly messageId!: string;
  @Prop(Array) private readonly arkText!: string[];
  @Prop(Number) private readonly timestamp!: number;
  @Prop(String) private readonly eid!: string;
  @Prop({ default: true }) private readonly loadAnswers!: boolean; // set to false to surpress the loading of answers

  private mdiDotsHorizontal = mdiDotsHorizontal;

  private aspectMessage!: string;
  private sourceType: schema.SourceType | null = null;
  private answers!: schema.ArkMessage[];
  private deleted = false;

  private get questionText(): string[] {
    if (this.deleted) return [];
    // TODO: once aspectMessage returns joined EIDs, this can update
    return this.arkText || [];
  }

  private get answerLoading(): boolean {
    return this.loadAnswers && this.$apollo.queries.answers.loading;
  }

  private get isOpenInDrawer(): boolean {
    return this.history.correlationId == this.messageId && this.history.open;
  }

  private get hasChatHistory(): boolean {
    return this.sourceType == schema.SourceType.Discord;
  }

  private showHistory() {
    if (this.isOpenInDrawer) {
      this.history.open = false
    } else if (this.messageId) {
      this.history.openDrawerTo({
        channelUid: this.channelUid,
        questionEid: this.eid,
        questionTimestamp: this.timestamp,
        answerEids: this.answers.map(answer => answer.aspectMessage?.eid || ''),
        correlationId: this.messageId,
      });
    }
  }
}
