import { schema } from '@/gql';

export type ChannelNodeType = 'category' | 'channel' | 'node' | 'source';

export interface ChannelNode {
  name: string;
  children: ChannelNode[];
  id: string;
  type: ChannelNodeType;
  entity: object | null;
}

export function flattenChannels(node: ChannelNode): ChannelNode[] {
  return node.children.reduce((acc, child) => acc.concat(flattenChannels(child)), [node])
}
export function countChannels(node: ChannelNode): number {
  return node.children.reduce((acc, child) => acc + countChannels(child), node.type == 'channel' ? 1 : 0)
}

export function buildChannelTreeFromQuery(org: schema.Org):  ChannelNode {
  const edges = org.sources?.edges || [];

  const children = edges.map((edge) => {
    const sourceRoot: ChannelNode = {
      name: edge.node.aspect.displayName,
      children: [],
      id: edge.node.uid,
      type: 'source',
      entity: edge.node,
    };

    const categories: { [name: string]: ChannelNode } = {};

    edge.node.channels.forEach((channel) => {
      const categoryName = channel.categoryName || 'default';

      if (channel.visible) {
        if (!categories[categoryName]) {
          categories[categoryName] = {
            name: categoryName,
            children: [],
            id: `${edge.node.uid}:${categoryName}`,
            type: 'category',
            entity: null,
          };
          sourceRoot.children.push(categories[categoryName]);
        }

        const category = categories[categoryName];

        category.children.push({
          name: channel.displayName,
          children: [],
          id: channel.uid,
          type: 'channel',
          entity: channel,
        });
      }
    });

    return sourceRoot;
  });

  return {
    name: 'root',
    children: children,
    id: org.uid,
    type: 'node',
    entity: null,
  };
}
