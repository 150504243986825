


































import { Vue, Component, Prop } from 'vue-property-decorator';
import SearchBox from './components/SearchBox.vue';
import SectionContainer from '@/components/section/SectionContainer.vue'
import ArkMessage from '@/components/output/ArkMessage.vue'
import { queries, schema } from '@/gql';
import { proxies } from '@/store';
import { mdiInformation } from '@mdi/js'

@Component({
  components: {
    SearchBox,
    SectionContainer,
    ArkMessage,
  },
})
export default class Search extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private snackbar = proxies.Snackbar;
  private mdiInformation = mdiInformation;

  private results: schema.ArkMessage[] = [];
  private query = '';
  private channelUids: string[] = [];
  private loading = false;

  private async doSearch(query: string) {
    this.results = [];
    this.loading = true;
    this.query = query;

    try {
      const { data } = await this.$apollo.query<{ searchQuestions: schema.ArkMessageConnection }>({
        query: queries.search,
        fetchPolicy: 'network-only', // see: https://github.com/vuejs/vue-apollo/issues/501
        variables: {
          channelUids: this.channelUids,
          query: query,
          limit: 3,
        },
      });

      this.results = data.searchQuestions.edges.map(edge => edge.node) || [];
    } catch (err) {
      this.snackbar.showError('There was an error doing your search');
      console.error(err)
    } finally {
      this.loading = false;
    }
  }
  private setChannelUidSelection(channelUids: string[]) {
    this.channelUids = channelUids;
  }

}
