






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { proxies } from '@/store';
import { queries, schema, mutations } from '@/gql';
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight } from '@mdi/js';

@Component({
  components: {
  },
  apollo: {
    channels: {
      fetchPolicy: 'cache-and-network',
      query: queries.sourceChannels,
      skip() {
        return !this.sourceUid;
      },
      variables(): schema.QuerySourceArgs {
        return {
          uid: this.sourceUid,
        };
      },
      update(query: schema.Query) {
        const channels = query.source?.channels || [];
        return channels.filter(channel => !!channel.visible);
      },
    },
  },
})
export default class AutoresponseChannels extends Vue {
  @Prop(String) private readonly sourceUid!: string
  private channels: schema.Channel[] = [];
  private snackbar = proxies.Snackbar;
  private mdiChevronDown = mdiChevronDown;
  private footerProps = {
    'next-icon':mdiChevronRight,
    'prev-icon':mdiChevronLeft,
  }

  private updating: boolean = false;

  private get channelTableHeaders() {
    return [
      {
        text: 'Channel Name',
        value: 'channelName',
      },
      {
        text: 'Category',
        value: 'categoryName',
      },
      {
        text: 'Autorespond',
        value: 'autoresponse',
      },
    ];
  }

  private get channelTableItems() {
    const channels = this.channels || []

    return channels.map(channel => {
      const config = JSON.parse(channel.configJson);
      return {
        uid: channel.uid,
        channelName: channel.displayName,
        categoryName: channel.categoryName,
        autoresponse: config.autoresponse,
      };
    })
  }

  private get loading() {
    return this.$apollo.queries.channels.loading || this.updating;
  }

  private async onAutoresponseChanged(uid: string, enabled: boolean) {
    if (this.updating) return;
    this.updating = true;

    // find config in channel data
    const selectedChannel = this.channels.find(channel => channel.uid == uid);
    if (!selectedChannel) return;

    const config =  JSON.parse(selectedChannel.configJson);
    if (!config) return;

    config.autoresponse = enabled;
    const configJson = JSON.stringify(config);
    const input: schema.ChannelUpdateInput = {
      uid,
      configJson,
    }

    try {
      await this.$apollo.mutate({
        mutation: mutations.channelUpdate,
        variables: { input },
      });
      this.snackbar.showSuccess('Channel Updated!');
    } catch (err) {
      console.error(err);
      this.snackbar.showError('There was an error changing settings!')
    } finally {
      this.updating = false;
    }
  }
}
