



















import { Vue, Component, Prop } from 'vue-property-decorator';
import PlaceholderCard from '@/components/section/PlaceholderCard.vue';
import HeadingCard from '@/components/section/HeadingCard.vue';
import { queries, schema } from '@/gql';
import sourceTypeMdi from '@/common/sourceTypeMdi';

import Status from './Status.vue';
import Autoresponse from './Autoresponse.vue';
import Backfill from './Backfill.vue';
import Channels from './Channels.vue';
import Admin from './Admin.vue'

@Component({
  components: {
    PlaceholderCard,
    HeadingCard,
    Status,
    Autoresponse,
    Channels,
    Backfill,
    Admin,
  },
  apollo: {
    source: {
      fetchPolicy: 'cache-and-network',
      query: queries.sourceConfig,
      skip() {
        return !this.sourceUid;
      },
      variables(): schema.QuerySourceArgs {
        return {uid: this.sourceUid};
      },
    },
  },
})
export default class DiscordCard extends Vue {
  @Prop(String) private readonly sourceUid!: string
  @Prop(String) private readonly orgUid!: string
  private source!: schema.Source;
  private discordIcon = sourceTypeMdi(schema.SourceType.Discord);

  private get loading() {
    return this.$apollo.queries.source.loading;
  }
}
