











































import { Vue, Component, Prop } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
import { queries, schema } from '@/gql';
import { mdiInformation } from '@mdi/js'
import dayjs from '@/common/dayjs';

@Component({
  components: {
    HeadingCard,
  },
  apollo: {
    tasks: {
      fetchPolicy: 'network-only',
      query: queries.tasks,
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {uid: this.orgUid};
      },
      update(query: schema.Query) {
        return query.org?.tasks || [];
      },
    },
  },
})
export default class SystemCard extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private tasks: schema.Task[] = [];
  private mdiInformation = mdiInformation;

  private get loading() {
    return this.$apollo.queries.tasks.loading;
  }

  private formatDate(timestamp: Date | string | number): string {
    return dayjs(timestamp).format('lll');
  }

  private statusText(value: number): string {
    switch(value) {
      case 0:
        return 'Not running';
      case 1:
        return 'Scheduled to run';
      case 2:
        return 'Running';
      case 3:
        return 'Error';
      case 4:
        return 'Completed';
      default:
        return 'Unknown'
    }
  }
}
