

































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import SectionContainer from '@/components/section/SectionContainer.vue';
import PlaceholderCard from '@/components/section/PlaceholderCard.vue';
import HeadingCard from '@/components/section/HeadingCard.vue';
import ChannelFilter from '@/components/input/ChannelFilter.vue';
import ArkMessage from '@/components/output/ArkMessage.vue';
import { queries, schema } from '@/gql';
import { mdiChevronDown, mdiInformation } from '@mdi/js';

@Component({
  components: {
    SectionContainer,
    PlaceholderCard,
    HeadingCard,
    ChannelFilter,
    ArkMessage,
  },
  apollo: {
    unansweredQuestions: {
      query: queries.recent,
      fetchPolicy: 'network-only',
      variables(): schema.QueryRecentQuestionsListArgs {
        return {
          channelUids: this.channelUids,
          questionsOnly: true,
          answered: false,
          limit: 2,
        };
      },
      error(err) {
        console.error(err);
      },
      skip() {
        return !this.channelUids || this.channelUids.length === 0;
      },
      update(query: schema.Query): schema.ArkMessage[] {
        return query.recentQuestionsList?.edges.map(edge => edge.node) || []
      },
    },
    answeredQuestions: {
      query: queries.recent,
      fetchPolicy: 'network-only',
      variables(): schema.QueryRecentQuestionsListArgs {
        return {
          channelUids: this.channelUids,
          questionsOnly: true,
          answered: true,
          limit: 10,
        };
      },
      error(err) {
        console.error(err);
      },
      skip() {
        return !this.channelUids || this.channelUids.length === 0;
      },
      update(query: schema.Query): schema.ArkMessage[] {
        return query.recentQuestionsList?.edges.map(edge => edge.node) || []
      },
    },
  },
})
export default class Recent extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private tab: number | null = null;
  private mdiChevronDown = mdiChevronDown;
  private mdiInformation = mdiInformation;

  private unansweredQuestions: schema.ArkMessage[] = [];
  private answeredQuestions: schema.ArkMessage[] = [];
  
  private expandSettings = false;
  private loadnextChannelUid = true;
  private channelUidsQueued: string[] = [];
  private channelUids: string[] = [];
  private channelStats = '';

  private get optionsHint() {
    return this.channelStats ? ` (${this.channelStats})` : '';
  }

  private get answeredLoading() {
    return this.$apollo.queries.answeredQuestions.loading;
  }

  private get unansweredLoading() {
    return this.$apollo.queries.unansweredQuestions.loading;
  }

  private doUpdate() {
    this.channelUids = this.channelUidsQueued;
  }

  private closeAndUpdate() {
    this.expandSettings = false;
    this.doUpdate();
  }

  private setChannelUidSelection(channelUids: string[]) {
    this.channelUidsQueued = channelUids;
    if (this.loadnextChannelUid) {
      this.doUpdate();
      this.loadnextChannelUid = false;
    }
  }
  private setChannelUidSelectionStats(text: string) {
    this.channelStats = text;
  }
}
