











































import { Vue, Component, Prop } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
import UserAvatarTooltip from '@/components/output/UserAvatarTooltip.vue';
import RelativeTimeTooltip from '@/components/tooltip/RelativeTimeTooltip.vue';
import KnowledgeBreadcrumbs from '@/components/output/KnowledgeBreadcrumbs.vue';
import { mdiDotsHorizontal } from '@mdi/js';

@Component({
  components: {
    HeadingCard,
    UserAvatarTooltip,
    RelativeTimeTooltip,
    KnowledgeBreadcrumbs,
  }
})
export default class ArkMessage extends Vue {
  @Prop(String) private readonly orgUid!: string;
  @Prop(String) private readonly messageEid!: string;
  @Prop(String) private readonly authorUid!: string;
  @Prop(Array) private readonly questionText!: string[];
  @Prop(Array) private readonly answerText!: string[];
  @Prop(Number) private readonly timestamp!: number;
  @Prop({ default: 200 }) private questionMaxLength!: number;
  @Prop({ default: 500 }) private answerMaxLength!: number;
  
  private mdiDotsHorizontal = mdiDotsHorizontal;

  private questionExpanded = false;

  private get questionCollapsedText() {
    if (this.questionText[0].length > this.questionMaxLength) {
      return this.questionText[0].slice(0, this.questionMaxLength) + "...";
    } else {
      return this.questionText[0];
    }
  }

  private get questionExpandable(): boolean {
    return this.questionText[0].length > this.questionMaxLength || this.questionText.length > 1;
  }

  private get answerCollapsedText() {
    if (this.answerText[0].length > this.answerMaxLength) {
      return this.answerText[0].slice(0, this.answerMaxLength) + "...";
    } else {
      return this.answerText[0];
    }
  }


}
