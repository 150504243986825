









































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mdiCalendar } from '@mdi/js';
import CardSheet from '@/components/section/CardSheet.vue'

@Component({
  components: {
    CardSheet,
  },
})
export default class SearchOptionsDateFilter extends Vue {
  private mdiCalendar = mdiCalendar;
  private date = '';
  private date2 = '';

  private menu = false;
  private menu2 = false;

  @Watch('date')
  private onStartDateChange(val: string) {
    const date = new Date(val);

    if (!val || Number.isNaN(date.getTime())) {
      this.$emit("startDate", null);
    } else {
      date.setHours(24);
      this.$emit("startDate", date);
    }
  }

  @Watch('date2')
  private onEndDateChange(val: string) {
    const date = new Date(val);

    if (!val || Number.isNaN(date.getTime())) {
      this.$emit("endDate", null);
    } else {
      date.setHours(24);
      this.$emit("endDate", date);
    }
  }
}
