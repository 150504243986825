



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
import ChannelFilter from '@/components/input/ChannelFilter.vue';
import SearchOptionsDateFilter from './SearchOptionsDateFilter.vue';
import { mdiMagnify, mdiChevronDown } from '@mdi/js'
import { proxies } from '@/store';


@Component({
  components: {
    HeadingCard,
    ChannelFilter,
    SearchOptionsDateFilter,
  }
})
export default class Search extends Vue {
  @Prop(String) private readonly orgUid!: string;
  @Prop(Boolean) private loading!: boolean;
  private mdiMagnify = mdiMagnify;
  private mdiChevronDown = mdiChevronDown;
  private snackbar = proxies.Snackbar;

  private expandSettings = false;
  private channelStats = '';
  private doSearchWhenChannelsLoaded = false;

  private query: string = '';
  private channelUids: string[] = [];
  private startDate: Date | null = null;
  private endDate: Date | null = null;

  private get searchLoading() {
    return this.loading || this.doSearchWhenChannelsLoaded;
  }

  private get cleanQuery(): string {
    return this.query?.replace(/[^\w\s]/gi, '').trim() || '';
  }

  private get optionsHint() {
    const hints = [
      this.channelStats || null,
      this.startDate ? 'start date set' : null,
      this.endDate ? 'end date set' : null,
    ]

    const filtered = hints.filter(item => !!item)
    if (filtered.length) {
      return ` (${filtered.join(', ')})`;
    } else {
      return '';
    }
  }

  private setChannelUidSelection(channelUids: string[]) {
    this.channelUids = channelUids;
    this.$emit('setChannelUidSelection', channelUids);
    if (this.doSearchWhenChannelsLoaded && !!this.cleanQuery) {
      this.doSearch();
    }
    this.doSearchWhenChannelsLoaded = false;
  }
  private setChannelUidSelectionStats(text: string) {
    this.channelStats = text;
  }
  private setStartDate(date: Date | null) {
    this.startDate = date;
  }
  private setEndDate(date: Date | null) {
    this.endDate = date;
  }
  private doSearch(): void {
    if (!this.cleanQuery) {
      this.snackbar.showWarning('Your query is empty! Try typing in a search query');
      return
    }

    this.expandSettings = false;
    if (!this.channelUids.length) {
      this.snackbar.showWarning('Your channel filter is empty! Please select at least one channel');
      return
    }

    if (this.cleanQuery != this.$route.query.search) {
      this.$router.push({query: {search: this.cleanQuery}})
    }
    this.$emit('doSearch', this.cleanQuery);
  }
  private mounted() {
    if (typeof this.$route.query.search === 'string') {
      this.query = this.$route.query.search;
      this.doSearchWhenChannelsLoaded = true;
    }
  }
}
