import { schema } from '../gql';
import { auth } from '@/plugins/firebase';

export interface OAuthLoginResults {
  userId: string;
  token: string;
  displayName: string;
  newAccount: boolean;
}

export interface OAuthSourceResults {
  token: string;
  displayName: string;
  newAccount: boolean;
  userSourceEid: string;
  sourceEid: string;
}

export interface OAuthBotResults {
  token: string;
  displayName: string;
  newAccount: boolean;
  userSourceEid: string;
  sourceEid: string;
  sourceUid: string;
}

export class OAuth {
  public static arkiveAuthPath = process.env.VUE_APP_OAUTH_ENDPOINT; // 'http://localhost:5000';
  public static arkivePlatformPath = process.env.VUE_APP_PLATFORM_ENDPOINT; // 'http://localhost:8080';

  public async openExternalSourceAuthPopup(sourceUid: string, sourceType: schema.SourceType, userToken: string | undefined): Promise<OAuthSourceResults | null> {
    const url = `${OAuth.arkiveAuthPath}/auth/${sourceType}/source/authorize/${sourceUid}/${userToken ? `${userToken}` : 'null'}`;
    const destination = `${OAuth.arkivePlatformPath}/oauth/account-link/popup/callback`;
    return this.openPopupWindow('Third Party Auth', url, destination) as Promise<OAuthSourceResults | null>;
  }

  public async openExternalLoginPopup(sourceType: string): Promise<OAuthLoginResults | null> {
    const url = `${OAuth.arkiveAuthPath}/auth/${sourceType}/accounts/sign-in`;
    const destination = `${OAuth.arkivePlatformPath}/oauth/popup/callback`;
    return this.openPopupWindow('Third Party Auth', url, destination) as Promise<OAuthLoginResults | null>;
  }

  public async openExternalBotPopup(
    sourceType: string,
    userToken: string,
    orgUid: string | undefined = undefined,
    newOrgDisplayname: string | undefined = undefined
  ): Promise<OAuthBotResults | null> {
    const url = `${OAuth.arkiveAuthPath}/auth/${sourceType}/bot/authorize`;
    const destination = `${OAuth.arkivePlatformPath}/oauth/add-arkive/popup/callback`;

    const params = {
      userToken,
    };
    if (orgUid) {
      (params as any).orgUid = orgUid;
    } else if (newOrgDisplayname) {
      (params as any).newOrgDisplayName = newOrgDisplayname;
    } else {
      throw new Error('Must supply either new org displayname or existing org uid');
    }

    return this.openPopupWindow('Third Party Auth', url, destination, params) as Promise<OAuthBotResults | null>;
  }

  public async openPopupWindow(title: string, url: string, destination: string, inParams = {}, width = 900, height = 800): Promise<OAuthLoginResults | OAuthSourceResults | OAuthBotResults | null> {
    // eslint-disable-next-line no-param-reassign
    const params = {
      ...inParams,
      destination,
      //token: auth.token || '',
      token: '',
    };

    const windowBounds = {
      x: (window.screen.width - width) / 2,
      y: (window.screen.height - height) / 2,
      width,
      height,
    };

    const windowParams: { [name: string]: string | number } = {
      resizable: 'no',
      toolbar: 'no',
      scrollbar: 'no',
      status: 'no',
      menubar: 'no',
      location: 'no',
      directories: 'no',
      top: windowBounds.y,
      left: windowBounds.x,
      screenX: windowBounds.x,
      screenY: windowBounds.y,
      width: windowBounds.width,
      height: windowBounds.height,
    };

    const strUrlParams = Object.keys(params)
      .map((key) => `${key}=${(params as never)[key]}`)
      .join('&');

    const strWindowParams = Object.keys(windowParams)
      .map((key) => `${key}=${windowParams[key]}`)
      .join(',');

    localStorage.removeItem('oauth_callback_state');
    const w = window.open(`${url}?${strUrlParams}`, title, strWindowParams);
    if (!w) {
      throw new Error('Could not open window');
    }
    w.focus();

    while (w.opener) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((cont) => setTimeout(cont, 1000));
    }

    const callback_state = localStorage.getItem('oauth_callback_state') as string;
    if (callback_state) {
      localStorage.removeItem('oauth_callback_state');
      return JSON.parse(atob(callback_state));
    }
    return Promise.resolve(null);
  }
}

export const oauth = new OAuth();
