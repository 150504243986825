



































import { Vue, Component, Watch, Prop, Emit, Ref } from 'vue-property-decorator';
import { queries, schema } from '@/gql';
import { mdiChevronDown, mdiCheckboxBlankOutline, mdiMinusBox, mdiCheckboxMarked } from '@mdi/js';
import CardSheet from '@/components/section/CardSheet.vue'
import sourceTypeMdi from '@/common/sourceTypeMdi';
import { ChannelNode, buildChannelTreeFromQuery, countChannels, flattenChannels } from '@/common/channelTree';

@Component({
  components: {
    CardSheet,
  },
  apollo: {
    rootNode: {
      fetchPolicy: 'cache-and-network',
      query: queries.channelTree,
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return { uid: this.orgUid };
      },
      update(query: schema.Query): ChannelNode {
        const root = buildChannelTreeFromQuery(query.org);
        this.selection = flattenChannels(root)
        this.totalChannels = countChannels(root)

        this.$nextTick(() => {
          this.treeView.updateAll(true);
        });

        return root;
      },
    },
  },
})
export default class ChannelFilter extends Vue {
  @Prop(String) private readonly orgUid!: string;
  @Ref('channelTree') private treeView!: any;

  private mdiCheckboxBlankOutline = mdiCheckboxBlankOutline;
  private mdiCheckboxMarked = mdiCheckboxMarked;
  private mdiChevronDown = mdiChevronDown;
  private mdiMinusBox = mdiMinusBox;
  private sourceTypeMdi = sourceTypeMdi;

  private get loading() {
    return !this.rootNode?.children.length || this.rootNode?.id !== this.orgUid;
  }

  private selection: ChannelNode[] = [];
  private rootNode!: ChannelNode;
  private totalChannels: number = 0;

  private get treeItems() {
    if (!this.rootNode) {
      return [];
    }
    if (this.rootNode.children.length === 0) {
      return [];
    }
    let current = this.rootNode;
    while (current.children.length === 1) {
      [current] = current.children;
    }
    return current.children;
  }

  @Watch('selection')
  private onSelectionChange() {
    const uids = this.selection.filter((channel) => channel.type === 'channel').map((channel) => channel.id);
    this.$emit("channelUidSelection", [...new Set(uids)]);

    if (uids.length < this.totalChannels) {
      this.$emit("channelUidSelectionStats", `${uids.length}/${this.totalChannels} channels selected`);
    } else {
      this.$emit("channelUidSelectionStats", '');
    }
  }
}
