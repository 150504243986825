

















































import { Vue, Component, Prop } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
import CardSheet from '@/components/section/CardSheet.vue';
import OAuthButton from '@/components/account/OAuthButton.vue';
import { gql } from 'apollo-boost';
import { schema } from '@/gql';
import { proxies } from '@/store';
import { SourceType } from '@arkive-ai/client-gateway-gql/dist/schema';
import { oauth } from '@/plugins/oauth';

@Component({
  components: {
    HeadingCard,
    CardSheet,
    OAuthButton,
  }
})
export default class Admin extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private snackbar = proxies.Snackbar;
  private user = proxies.User;
  @Prop(String) private sourceUid!: string;

  private uninstallDialog = false;
  private uninstallLoading = false;
  private reinstallLoading = false

  private async reinstall() {
    if (this.reinstallLoading) return;
    this.reinstallLoading = true;

    await this.user.refreshLogin();
    const results = await oauth.openExternalBotPopup(
      SourceType.Discord,
      this.user.token,
      this.orgUid,
      undefined,
    );
    if (results) {
      this.snackbar.showSuccess('Bot reinstalled');
      this.$router.go(0);
    } else {
      this.snackbar.showWarning('Bot reinstall cancelled');
    }
    this.reinstallLoading = false;
  }

  private async uninstall() {
    if (this.uninstallLoading) return;

    try {
      await this.$apollo.mutate<schema.Mutation>({
        mutation: gql`
          mutation sourceDelete($input: SourceDeleteInput!) {
            sourceDelete(input: $input)
          }
        `,
        variables: {
          uid: this.sourceUid,
        },
      });
      this.$router.go(0);
      this.snackbar.showSuccess('Bot uninstalled!');
      this.uninstallDialog = false;
    } catch (err) {
      this.snackbar.showError('There was an error uninstalling the bot');
      console.error(err);
    } finally {
      this.uninstallLoading = false;
    }
  }
}
