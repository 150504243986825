




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { proxies } from '@/store';
import { gql } from 'apollo-boost';
import { schema, mutations } from '@/gql';
import CardSheet from '@/components/section/CardSheet.vue';
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight } from '@mdi/js';

@Component({
  components: {
    CardSheet,
  },
  apollo: {
    channels: {
      fetchPolicy: 'cache-and-network',
      query: gql`
        query communitySettingsBackfill($uid: String!) {
          source(uid: $uid) {
            id
            channels {
              id
              displayName
              categoryName
              uid
              visible
              configJson
              backfill {
                id
                progress
                completed
              }
            }
          }
        }
      `,
      skip() {
        return !this.sourceUid;
      },
      variables(): schema.QuerySourceArgs {
        return {
          uid: this.sourceUid,
        };
      },
      update(query: schema.Query) {
        const channels = query.source?.channels || [];
        return channels.filter(channel => !!channel.visible);
      },
    },
  },
})
export default class Backfill extends Vue {
  @Prop(String) private readonly sourceUid!: string
  private snackbar = proxies.Snackbar;
  private mdiChevronDown = mdiChevronDown;
  private footerProps = {
    'next-icon':mdiChevronRight,
    'prev-icon':mdiChevronLeft,
  }

  private channels: schema.Channel[] = [];
  private expandSettings: boolean = false;

  private updating = false;
  
  private get loading() {
    return this.$apollo.queries.channels.loading || this.updating;
  }

  private get channelTableHeaders() {
    return [
      {
        text: 'Channel Name',
        value: 'channelName',
      },
      {
        text: 'Category',
        value: 'categoryName',
      },
      {
        text: 'Backfill',
        value: 'backfillEnabled',
      },
      {
        text: 'Backfill Progress',
        value: 'backfillProgress',
      },
    ];
  }

  private get channelTableItems() {
    const channels = this.channels || []

    return channels.map(channel => {
      const config = JSON.parse(channel.configJson);
      return {
        uid: channel.uid,
        channelName: channel.displayName,
        categoryName: channel.categoryName,
        backfillEnabled: config.backfill,
        backfillProgress: channel.backfill.progress,
      };
    });
  }

  private get channelsEnabled() {
    return this.channels.reduce((count, channel) => {
      if (channel.visible) {          
        const config = JSON.parse(channel.configJson);
        if (config.backfill) {
          return count + 1;
        }
      }
      return count;
    }, 0)
  }

  private async onBackfillChanged(uid: string, enabled: boolean) {
    if (this.updating) return;
    this.updating = true;

    // find config in channel data
    const selectedChannel = this.channels.find(channel => channel.uid == uid);
    if (!selectedChannel) return null;

    const config = JSON.parse(selectedChannel.configJson);
     if (!config) return;

    config.backfill = enabled;
    const configJson = JSON.stringify(config);
    const input: schema.ChannelUpdateInput = {
      uid,
      configJson,
    };

    try {
      await this.$apollo.mutate({
        mutation: mutations.channelUpdate,
        variables: { input },
      });
      this.snackbar.showSuccess('Channel Updated!');
    } catch (err) {
      console.error(err);
      this.snackbar.showError('There was an error changing settings!')
    } finally {
      this.updating = false;
    }
  }
}
